import React, { useMemo, useState } from "react";
import Select from "react-select";
import InputField from "../../../../view/elements/Input";
import { toast } from "react-toastify";
import { SAVE_CUST_DETAILS } from "../../../../queries/sangam/customer.gql";
import { executeGraphQLMutationFinex } from "../../../../common/executeGraphQLQuery";

const AGE_TABS = [
  { id: "30", label: "21-40 years", value: "30" },
  { id: "45", label: "40-50 years", value: "45" },
  { id: "55", label: "50 years +", value: "55" },
];

const CREDIT_SCORE_TABS = [
  { id: "650", label: "Below 700", value: "650" },
  { id: "720", label: "700-730", value: "720" },
  { id: "740", label: "730-750", value: "740" },
  { id: "770", label: "750-780", value: "770" },
  { id: "800", label: "Above 780", value: "800" },
  { id: "-1", label: "Not Available", value: "-1" },
];

const LoanOfferBasicDetails = ({
  breData,
  handleBreDataChange,
  masterdata,
  setNextActive,
}) => {
  const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
  const [errors, setErrors] = useState({});

  const ALL_OPTIONS = useMemo(() => {
    const options = masterdata ? masterdata : {};
    options["company_type"] = options["company_type"]?.map((obj) => ({
      ...obj,
      value: obj.id,
    }));
    return options;
  }, [masterdata]);

  const handleTabChange = (name, value) => handleBreDataChange(name, value);

  const handleChange = (key, value) => {
    setErrors((current) => ({
      ...current,
      [key]: "",
    }));
    handleBreDataChange(key, value);
  };

  const handleNext = () => {
    if (!breData.age || breData.age === "" || breData.age === null) {
      return setErrors((current) => ({
        ...current,
        age: "Age is required",
      }));
    }
    if (
      breData.profession === 1 &&
      (!breData?.work_experience ||
        breData.work_experience === "" ||
        breData.work_experience === null)
    ) {
      return setErrors((current) => ({
        ...current,
        work_experience: "Work Experience is required",
      }));
    }
    handleSubmit();
  };

  const handleSubmit = async () => {
    try {
      const {
        lead_id,
        profession,
        // company_name,
        company_type,
        work_experience,
        cibil_score,
        business_vintage,
        industry,
        first_name,
        last_name,
        mobile,
        age,
      } = breData;
      if (lead_id) {
        const mutation = SAVE_CUST_DETAILS;
        const variables = {
          api_called_by: "saathi_web",
          partner_id: loginUserInfo?.dealer_id,
          LeadAdditionalInput: {
            lead_id,
            lead_details: {
              lead_id,
              company_type,
              cibil_score,
            },
          },
        };
        if (profession === 1) {
          // variables.LeadAdditionalInput.lead_details["company_name"] =
          //   company_name;
          variables.LeadAdditionalInput.lead_details["work_experience"] =
            +work_experience;
        } else if (profession === 3) {
          const currentDate = new Date();
          variables.LeadAdditionalInput["customer"] = {
            business_vintage_years: +business_vintage,
            industry,
            first_name,
            last_name,
            mobile,
            dob: new Date(
              currentDate.setFullYear(currentDate.getFullYear() - age)
            ),
          };
        }
        await executeGraphQLMutationFinex(mutation, variables);
        toast.success("Success");
      }
      setNextActive();
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <div className="commonloancard">
        <div className="mainheadingall">Basic Details</div>
        <div className="loanofferallbox">
          <div className="allheadingincome">
            Age<span style={{ color: "red" }}>*</span>
          </div>
          <div className="cibilloanflex">
            {AGE_TABS.map((tab) => (
              <div
                key={tab.id}
                className={`cibilhometab ${
                  breData.age === tab.value ? "active" : ""
                }`}
                onClick={() => handleTabChange("age", tab.value)}
              >
                <span>{tab.label}</span>
              </div>
            ))}
            {errors?.age && <div className="formerrorinput">{errors.age}</div>}
          </div>

          <div className="row">
            <div className="col-sm-4" style={{ marginBottom: 20 }}>
              <label>Company Type</label>
              <Select
                id="company_type"
                onChange={(e) => handleChange("company_type", e.value)}
                options={ALL_OPTIONS?.["company_type"] || []}
                name="company_type"
                placeholder="Company Type"
                value={ALL_OPTIONS?.["company_type"]?.find(
                  (option) => option.value === breData.company_type
                )}
              />
            </div>
            {breData.profession == 3 ? (
              <>
                <div className="col-sm-4">
                  <InputField
                    type="text"
                    name="industry"
                    id="industry"
                    placeholder="Enter Industry"
                    label="Industry"
                    onChange={(e) => handleChange("industry", e.target.value)}
                    value={breData.industry}
                  />
                </div>
                <div className="col-sm-4">
                  <InputField
                    type="number"
                    name="business_vintage"
                    id="business_vintage"
                    placeholder="Enter Business Vintage Years"
                    label="Business Vintage (In Years)"
                    onChange={(e) =>
                      handleChange("business_vintage", e.target.value)
                    }
                    value={breData.business_vintage}
                  />
                </div>
              </>
            ) : (
              <>
                {/* <div className="col-sm-4">
                  <InputField
                    type="text"
                    name="company_name"
                    id="company_name"
                    placeholder="Enter Company Name"
                    label="Company Name"
                    onChange={(e) =>
                      handleChange("company_name", e.target.value)
                    }
                    value={breData.company_name}
                  />
                </div> */}
                <div className="col-sm-4">
                  <InputField
                    type="number"
                    name="work_experience"
                    id="work_experience"
                    placeholder="Enter Work Experience"
                    label="Work Experience (in Years)"
                    required={true}
                    maxLength={3}
                    onChange={(e) =>
                      handleChange("work_experience", e.target.value)
                    }
                    value={breData.work_experience}
                  />
                  {errors?.work_experience && (
                    <div className="formerrorinput">
                      {errors.work_experience}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>

          <div className="allheadingincome">Credit Score Range</div>
          <div className="cibilloanflex">
            {CREDIT_SCORE_TABS.map((tab) => (
              <div
                key={tab.id}
                className={`cibilhometab ${
                  breData.credit_score === tab.value ? "active" : ""
                }`}
                onClick={() => handleTabChange("credit_score", tab.value)}
              >
                <span>{tab.label}</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div style={{ textAlign: "end" }}>
        <button className="loanbtnnext" onClick={handleNext}>
          Next
        </button>
      </div>
    </>
  );
};

export default LoanOfferBasicDetails;

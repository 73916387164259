import React, { useState } from "react";
import MyEarningsPopup from "./MyEarningsPopup";
import Modal from "../../view/common/Modal";
import { executeGraphQLMutation, executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import {  INVOICE_LISTING_DATA } from "../../queries/payout.gql";
import NoDataFound from "../../common/NoDataFound";

const MyEarningListTable = (props) => {
  const {
    activeTab,
    expandedRow,
    handleTabClick,
    handleRowClick,
    paidInvoiceData,
  } = props;

  const [projectedData, setProjectedData] = useState([]);
  const [schemeData, setSchemeData] = useState([]);
  const [showView, setShowView] = useState(false);
  const [invoiceData, setInvoiceData] = useState("");

  const handlePayoutApi = async (data, paidWholeData) => {
    if (activeTab === "Projected") {
      setInvoiceData(data);
      setProjectedData(paidWholeData);
    } else if (activeTab === "Earned" || activeTab === "Paid") {
      const response = await executeGraphQLMutation(INVOICE_LISTING_DATA(data.id));
      setInvoiceData(response.data.invoice_payouts[0]);
    }
    togglePopup();
  };

  const togglePopup = () => {
    setShowView((prevShowView) => !prevShowView);

    if (!showView) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  const handleInvoiceData = async (index , id) => {
    handleRowClick(index)
    const result = await executeGraphQLQuery(INVOICE_LISTING_DATA(id));
    setSchemeData(result.data.invoice_payouts);
  };


  const formatCurrency = (x) => {
    x=x.toString();
    var lastThree = x.substring(x.length-3);
    var otherNumbers = x.substring(0,x.length-3);
    if(otherNumbers != '')
        lastThree = ',' + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res
  }
  return (
    <div className="tablecontainer">
      <div className="newtabsContainer">
        <ul className="newtabs">
          {["Projected", "Earned", "Paid"].map((tab) => (
            <li
              key={tab}
              className={`newtabsItem ${activeTab === tab ? "active" : ""}`}
            >
              <div className="newtabContent" onClick={() => handleTabClick(tab)}>
                {tab}
                <span className="newtabSpan"></span>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {activeTab === "Projected" && (
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Lead ID</th>
                <th>Name</th>
                <th>Bank Name</th>
                <th>Product Type</th>
                <th>Disb. Amount</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              { paidInvoiceData.length > 0 ?  paidInvoiceData.map((data, index) => (
                <React.Fragment key={index}>
                  <tr onClick={() => handleRowClick(index)}>
                    <td>{data?.lead_id || '--'}</td>
                    <td>{data?.customer_name || '--'}</td>
                    <td>{data?.bank_name || '--'}</td>
                    <td>{data?.products || '--'}</td>
                    <td>
                      {formatCurrency(data.total_disbursal_amount || '--')}
                     
                    
                    </td>
                    <td>{expandedRow === index ? "-" : "+"}</td>
                  </tr>
                  {expandedRow === index && (
                    <tr>
                      <td colSpan="6">
                        <table>
                          <thead style={{ background: "#ccc" }}>
                            <tr>
                              <th style={{ color: "#000" }}>Tranche ID</th>
                              <th style={{ color: "#000" }}>Product Type</th>
                              <th style={{ color: "#000" }}>Disb. Amount</th>
                              <th style={{ color: "#000" }}>Date</th>
                              <th style={{ color: "#000" }}>Projected Amt</th>
                              <th style={{ color: "#000" }}>Breakup</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.payouts.map((payout, subIndex) => (
                              <tr key={subIndex}>
                                <td>{payout?.tranche_id}</td>
                                <td>{payout?.products}</td>
                                <td>{formatCurrency(payout?.disbursal_amount)}</td>
                                <td>
                                  {new Date(payout?.disbursal_date).toLocaleDateString()}
                                </td>
                                <td>{formatCurrency(payout?.payout_amount)}</td>
                                <td
                                  className="viewleadcolor"
                                  onClick={() => handlePayoutApi(payout, paidInvoiceData[index])}
                                >
                                  View
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              )) :  <td colspan='5' style={{textAlign : "center"}}><NoDataFound/></td>}
            </tbody>
          </table>
        </div>
      )}

      {(activeTab === "Paid" || activeTab === "Earned") && (
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Invoice No</th>
                <th>Raised Date</th>
                <th>Amount</th>
                <th>Invoice</th>
                <th>Breakup</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              { paidInvoiceData && paidInvoiceData.length>0 ? paidInvoiceData.map((data, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td>{data.invoice_no || '--'}</td>
                    <td>{new Date(data.created_at).toLocaleString()}</td>
                    <td>{data.payout_amount || '--'}</td>
                    <td>
                      <a
                        href={
                          activeTab === "Paid"
                            ? data.approved_invoice_url
                            : data.pending_invoice_url
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="ic-content_copy icons-normal"></i>
                      </a>
                    </td>
                    <td onClick={() => handleInvoiceData(index , data.id)}>
                      {expandedRow === index ? "-" : "+"}
                    </td>
                  </tr>
                  {expandedRow === index && (
                    <tr>
                      <td colSpan="6">
                        <table>
                          <thead style={{ background: "#ccc" }}>
                            <tr>
                              <th style={{ color: "#000" }}>Lead ID</th>
                              <th style={{ color: "#000" }}>Name</th>
                              <th style={{ color: "#000" }}>Product Type</th>
                              <th style={{ color: "#000" }}>Disb. Amount</th>
                              <th style={{ color: "#000" }}>Date</th>
                              <th style={{ color: "#000" }}>Payout Amt</th>
                              <th style={{ color: "#000" }}>Breakup</th>
                            </tr>
                          </thead>
                          <tbody>
                            {schemeData.map((payout, subIndex) => (
                              <tr key={subIndex}>
                                <td>{payout.payouts.partnerleads.lead_id}</td>
                                <td>{payout.payouts.partnerleads.customer_name}</td>
                                <td>{payout.products}</td>
                                <td>{payout.payouts.partnerleads.disbursal_amount}</td>
                                <td>{new Date(payout.payouts.partnerleads.disbursal_date).toLocaleDateString()}</td>
                                <td>{payout.payout_amount}</td>
                                <td
                                  className="viewleadcolor"
                                  onClick={() => handlePayoutApi(data)}
                                >
                                  View
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                  
                    
                
                </React.Fragment> 
              )) :
              <td colspan='5' style={{textAlign : "center"}}><NoDataFound/></td>}
            </tbody>
          </table>
        </div>
      )}

      <Modal isOpen={showView} togglePopup={togglePopup} width={800}>
        <MyEarningsPopup invoiceData={invoiceData} projectedData={projectedData} />
      </Modal>
    </div>
  );
};

export default MyEarningListTable;

import React, { useEffect, useState } from "react";
import InputField from "../../view/elements/Input";
import { fileUploader, postApiUtility } from "../Utility/user.utility";
import FileInput from "../NewLogin/Components/Input/FileInput";
import { validate } from "graphql";
import MasterService from "../../service/MasterService";
import { toast } from "react-toastify";
import Modal from "../../view/common/Modal";
import caution from "../../webroot/images/caution.svg";
import smallcaution from "../../webroot/images/smallcaution.svg";

const KycProfile = (props) => {
  const selectedOption = props.selectedOption;
  const error = props.errors;
  const dealer_hash_id = props.dealer_hash_id;
  const getOnChangeValue = props.getOnChangeValue
  const docList = props.docList
  const handleRetryPan = props.handleRetryPan
  const retryPan = props.retryPan
  const { kyc_save_value, set_kyc_save_value } = props;
  const { gst_val_from_pan } = props;
  const kyc_save_value_change = props.kyc_save_value
  const { HandleKycData, formData } = props;
  const [fileUploadArr, setFileUploadArr] = useState([]);
  const [pan, setPan] = useState(props.docsImages.pan);
  const [aadharFront, setAadharFront] = useState(props.docsImages.aadhar_front);
  const [aadharBack, setAadharBack] = useState(props.docsImages.aadhar_back);
  const [msme, setMsme] = useState(props.docsImages.msme);
  const [gst, setGst] = useState(props.docsImages.gst);
  const [rejectedPan, setRejectedPan] = useState(true)
  // const [kyc_save_value , set_kyc_save_value] = useState({})
  const [formValues, setFormValues] = useState({
    pan_card: formData.pan_no || "",
    aadhar_card: formData.aadhar_no || "",
    msme_number: formData.msme_number || "",
    gstin_number: formData.gst_number || gst_val_from_pan || "",
    is_msme: formData.is_msme || "0",
    is_gst: formData.is_gst || "0",
  });
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const togglePopup = () => {
    setIsModalOpen(!isModalOpen);
    console.log(isModalOpen)
    if (!isModalOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };
  useEffect(() => {
    setFormValues((prevValues) => ({
      ...prevValues,
      pan_card: formData.pan_no || "",
      aadhar_card: formData.aadhar_no || "",
      msme_number: formData.msme_number || "",
      gstin_number: formData.gst_number || gst_val_from_pan || "",
      is_msme: formData.is_msme || "0",
      is_gst: formData.is_gst || "0",
    }));

    if (props.gst_check === true) {
      setFormValues((prevValues) => ({
        ...prevValues,
        is_gst: "1",
      }));
    }

    if ( formData?.is_aadhar_linkded === 1) {
      setRejectedPan(false)
     }
  }, [formData, props.gst_check]);


  useEffect(() => {
    HandleKycData(formValues, fileUploadArr, pan, aadharFront, aadharBack, msme, gst);
    let result = docList.find((data) => data.kyc_master_id === 2);
    if (result?.reject_reason !== null && result?.verified_status === "0") {
      setRejectedPan(false)
    }
  }, [formValues, fileUploadArr, HandleKycData]);

  const handleFileChange = async (e) => {
    const { name, files } = e.target;

    if (files.length > 0) {
      const response = await fileUploader(files[0]);
      set_kyc_save_value((prev) => ({
        ...prev,
        [name]: response.file_url
      }));
      const kycMasterIds = {
        pan_card_file: 3,
        aadhar_card_front_file: 1,
        aadhar_card_back_file: 2,
        msme_file: 7,
        gstin_file: 5,
        cancel_checque: 4,
      };
      if (selectedOption === 2) {
        kycMasterIds.pan_card_file = 6;
      }
      setFormValues((prevState) => ({
        ...prevState,
        [name]: files,
      }));

      if (kycMasterIds[name] && response) {
        setFileUploadArr((prevState) => [
          ...prevState,
          {
            kyc_master_id: 1,
            kyc_child_id: 0,
          },
          {
            kyc_master_id: 1,
            dealer_id_hash: dealer_hash_id,
            verified_status: "1",
            kyc_master_id: kycMasterIds[name],
            file_name: response.file_name,
            file_url: response.file_url,
          },
        ]);
      }

      setFormValues((prev) => ({
        ...prev,
        [name]: response.file_url,
      }));

      switch (name) {
        case "pan_card_file":
          setPan(response.file_url);
          break;
        case "aadhar_card_front_file":
          setAadharFront(response.file_url);
          break;
        case "aadhar_card_back_file":
          setAadharBack(response.file_url);
          break;
        case "msme_file":
          setMsme(response.file_url);
          break;
        case "gstin_file":
          setGst(response.file_url);
          break;
        default:
          break;
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const capitalizedValue = value.toUpperCase();

    setFormValues((prev) => ({
      ...prev,
      [name]: capitalizedValue,
    }));

    set_kyc_save_value((prev) => ({
      ...prev,
      [name]: capitalizedValue
    }));
  };
  getOnChangeValue(kyc_save_value)

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: checked ? "1" : "0",
    }));
  };

  const getVerfiedStatus = (value) => {

    let result = docList.find((data) => data.kyc_master_id === value);
    if (result && result.verified_status === "1") {

      return <div className="verificationStatus">Verified</div>;
    }
    else if (result && result.verified_status === "0" && result.reject_reason >0) {
      if (result.reject_reason !== null) {

        return <div className="RejectedStatus">Rejected</div>;
      }
    }
      else
        return <div className="pendingStatus" style={{marginLeft : "25%"}}>Under Review</div>
   

    return null;
  };

  const handlePanRetry = async () => {
    if (!formData.pan_no) {
      toast.error("Please Enter Pan Card")
      return;
    }
    formData.pan_no = formValues.pan_card;


    const panDataResponse = await MasterService.get(`/partner/partner/pan-validation?pan_number=${formValues.pan_card}&partner_id=${formData.id}&user_id=${formData.user_id}&retry=1`)
    const panData = panDataResponse.data.data

    if (panData?.data?.aadhaarSeedingStatus === true) {
      toast.success("Pan  Aadhar linked successfully");
      setRejectedPan(false);
      handleRetryPan(true)
      togglePopup()
    } else {
      toast.error("Pan  Aadhar not linked")
      togglePopup()
      handleRetryPan(false)
    }
  }
  return (
    <div className="myaccountpadding">
      <h6 className="kycheadingmain">Documents</h6>
      <div>Complete your KYC process to ensure timely payouts</div>
      {selectedOption === "individual" && (
        <>
          <div className="row" style={{ marginTop: 20 }}>
            <div className="col-md-4 fontsiz14">
              {/* {kyc_save_value_change.pan_card ? kyc_save_value_change.pan_card : formValues.pan_card && <div className="verificationStatus">Under Review</div>} */}
              {formValues.pan_card && getVerfiedStatus(3)}
              <InputField
                type="text"
                name="pan_card"
                id="pan_card"
                placeholder="Enter PAN Number"
                label="Pan Card"
                value={kyc_save_value_change.pan_card ? kyc_save_value_change.pan_card : formValues.pan_card}
                onChange={handleInputChange}
                required={true}
                error={error.pan_no}
                maxLength="10"
                disabled={true}
              />
            </div>
            <div className="col-md-4">
              <label></label>
              <FileInput
                name="pan_card_file"
                id="pan_card_file"
                placeholder="Pan Card"
                onChange={handleFileChange}
                fileUrl={kyc_save_value.pan_card_file ? kyc_save_value.pan_card_file : pan}
                required={true}
              />
            </div>
          </div>
          <div className="row">
            {  <div className="col-md-4 fontsiz14">
              {formValues.pan_card && getVerfiedStatus(2)}
             { retryPan === true ?  <>
             <InputField
                type="text"
                name="aadhar_card"
                id="aadhar_card"
                placeholder="Enter Aadhar Card"
                label="Aadhar Card"
                value={kyc_save_value.aadhar_card ? kyc_save_value.aadhar_card :formValues.aadhar_card}
                onChange={handleInputChange}
                required={true}
                error={error.aadhar_no}
                maxLength="12"
                disabled = {true}
              /> 
              
              </>
              :
              <div className="form-control backcolorunique" style={{marginTop : "20px"}}>
                <div className="panadhrrecheckcontainer">
                  <div className="imgcircle" style={{marginRight : "10px"}}>
                    <img
                      src={smallcaution}
                      alt="panaadhar logo"
                      width={18}
                      height={18}

                    />
                  </div>
                  <div className="panaadharpara20"> PAN & Aadhaar should not be <br /> linked to avoid 20% TDS deductions.
                  </div>
                  <button className="receckbtn" onClick={handlePanRetry}>Re-check</button>
                </div>
              </div>}
            </div>}
            {/* <div onClick={() => { handlePanRetry() }}>
              retry
            </div> */}
            <div className="col-md-4">
              <label></label>
              <FileInput
                name="aadhar_card_front_file"
                id="aadhar_card_front_file"
                placeholder="Aadhar Card Front"
                onChange={handleFileChange}
                fileUrl={kyc_save_value.aadhar_card_front_file ? kyc_save_value.aadhar_card_front_file : aadharFront}
                required={true}
                error={error.aadharFront}
              />
            </div>
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <label></label>
                <FileInput
                  name="aadhar_card_back_file"
                  id="aadhar_card_back_file"
                  placeholder="Aadhar Card Back"
                  onChange={handleFileChange}
                  fileUrl={kyc_save_value.aadhar_card_back_file ? kyc_save_value.aadhar_card_back_file : aadharBack}
                  required={true}
                  error={error.aadharBack}
                />
              </div>
            </div>
          </div>
        </>
      )}
      {selectedOption === "company" && (
        <>
          <div className="row" style={{ marginTop: 20 }}>
            <div className="col-md-4">
              {/* {kyc_save_value_change.pan_card ? kyc_save_value_change.pan_card : formValues.pan_card && <div className="verificationStatus">Under Review</div>} */}
              {formValues.pan_card && getVerfiedStatus(6)}
              <InputField
                type="text"
                name="pan_card"
                id="pan_card"
                placeholder="Enter PAN Number"
                label="Pan Card"
                value={kyc_save_value_change.pan_card ? kyc_save_value_change.pan_card : formValues.pan_card}
                onChange={handleInputChange}
                required={true}
                error={error.pan_no}
                maxLength="10"
                disabled={true}
              />
            </div>
            <div className="col-md-4">
              <label>Upload PAN Card</label>
              <FileInput
                name="pan_card_file"
                id="pan_card_file"
                placeholder="Pan Card"
                onChange={handleFileChange}
                fileUrl={kyc_save_value.pan_card_file ? kyc_save_value.pan_card_file : pan}
                required={true}
              />
            </div>
          </div>
          <div className="row" style={{ marginTop: 20 }}>
            <div className="col-md-4">
              {formValues.msme_number && getVerfiedStatus(7)}
              <InputField
                type="text"
                name="msme_number"
                id="msme_number"
                placeholder="Enter MSME Number"
                label="MSME Number*"
                value={kyc_save_value.msme_number ? kyc_save_value.msme_number : formValues.msme_number}
                onChange={handleInputChange}
                error={error.msme_number}
                disabled={formValues.is_msme !== "1"} // Disable if MSME is not selected
              />
            </div>
            <div className="col-md-4">
              <div className="basiccheckbox">
                <input
                  type="checkbox"
                  name="is_msme"
                  id="is_msme"
                  checked={formValues.is_msme === "1"}
                  onChange={handleCheckboxChange}
                  value="1"
                />
                <label>MSME Registered</label>
              </div>
              <FileInput
                name="msme_file"
                id="msme_file"
                placeholder="MSME number"
                onChange={handleFileChange}
                fileUrl={kyc_save_value.msme_file ? kyc_save_value.msme_file : msme}
                required={true}
                error={error.msme}
                disabled={formValues.is_msme !== "1"} // Disable if MSME is not selected
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              {formValues.gstin_number && getVerfiedStatus(5)}
              <InputField
                type="text"
                name="gstin_number"
                id="gstin_number"
                placeholder="Enter GSTIN Number"
                label="GSTIN Number*"
                value={kyc_save_value.gstin_number ? kyc_save_value.gstin_number : formValues.gstin_number}
                onChange={handleInputChange}
                error={error.gst_number}
                disabled={formValues.is_gst !== "1"} // Disable if GST is not selected
              />
            </div>
            <div className="col-md-4">
              <div className="basiccheckbox">
                <input
                  type="checkbox"
                  name="is_gst"
                  id="is_gst"
                  checked={formValues.is_gst === "1"}
                  onChange={handleCheckboxChange}
                  value="1"
                />
                <label>GST Registered</label>
              </div>
              <FileInput
                name="gstin_file"
                id="gstin_file"
                placeholder="GST number"
                onChange={handleFileChange}
                fileUrl={kyc_save_value.gstin_file ? kyc_save_value.gstin_file : gst}
                required={true}
                error={error.gst_file}
                disabled={formValues.is_gst !== "1"} // Disable if GST is not selected
              />
            </div>
          </div>
        </>
      )}
      <Modal isOpen={isModalOpen} width={800} removeHeight={true} togglePopup={togglePopup} >
        {isModalOpen && (
          <div className="">
            <div className="panaadharcauction">
              <img
                src={caution}
                alt="caution Image"
                // width={120}
                // height={120}
              />
            </div>
            <div className="panadartext">PAN-Aadhaar Still Not Linked!</div>
            <div className="panadartext2">Link them soon to avoid 20% TDS deductions</div>
            <div className="pleasenote">
              <b>Please Note:</b>It may take 7 working days for PAN-Aadhaar linkage to reflect on govt websites. In case you have linked it already, please try again in a few days.
            </div>
            <div className="panaadharbtn">
              <button className="backButton" onClick={togglePopup}>Go Back</button>
              <a 
               href="https://eportal.incometax.gov.in/iec/foservices/#/pre-login/bl-link-aadhaar" 
               target="_blank" 
               rel="noopener noreferrer"
             >
             <button className="linkButton">Check how to link PAN-Aadhaar</button>
              </a>

            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default KycProfile;
import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { getNumberFormat_IN } from "./MyLeads/helpers/helpers";

const BankCarouselList = ({ bankCarouselData, handleRecalculate }) => {
  const handleRecalculateClick = () => {
    handleRecalculate();
  }
  return (
    <>
    <div className="newbankcard">
    <div className="loanbankflexhed">
    <div className="newloanofferhead">Loan Offers</div>
    <div><a href="#" className="offerrecal" onClick={handleRecalculateClick}>Recalculate</a></div>
    </div>
      <Carousel
        autoPlay={false}
        interval={2000}
        showArrows={true}        // Show arrows
        showIndicators={false}
        showThumbs={false}
        infiniteLoop={true}
        stopOnHover={true}
        swipeable={true}
        emulateTouch={true}
        showStatus={false}
        transitionTime={500}
      >
        {bankCarouselData.map((offer, index) => (
          <div key={index} className="bankcarouselcard">
            <div className="bankcarousellist">
              <div className="blistankname">
                <div>
                  <img
                    src={offer.bank?.logo}
                    alt="bank logo"
                    width={20}
                    height={20}
                  />
                </div>
                <span>{offer.bank?.banklang.bank_name}</span>
              </div>
              <div className="bankpricelist">
                <div>
                  <div className="priceinamount">₹ {getNumberFormat_IN(+offer.loan_amount)}</div>
                  <div className="priceinmonth">Loan Amount</div>
                </div>
                <div>
                  <div className="priceinamount">₹ {getNumberFormat_IN(+offer.emi)}</div>
                  <div className="priceinmonth">Monthly EMI</div>
                </div>
                <div>
                  <div className="priceinamount">{offer.roi}%</div>
                  <div className="priceinmonth">Rate of interest</div>
                </div>
              </div>
              <div className="bankonwordsnew">*onwards</div>
            </div>
            <div className="bankprocessing">
              <span>.</span>
              {offer.processing_fee || "N/A"}
            </div>
          </div>
        ))}
      </Carousel>
      </div>
    </>
  );
};

export default BankCarouselList;

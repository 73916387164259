import React, { useState, useEffect } from "react";
import "../../../src/saathi-web/saathi-web-style/mainlead.css";
import InputField from "../../view/elements/Input";
import Select from "react-select";
import { Link } from "react-router-dom";
import MasterService from "../../service/MasterService";
import { toast } from "react-toastify";
import VerifyOtpModal from "./otp/verify-otp-popup";
import PageLoader from '../../view/elements/PageLoader'

const NewTeamMember = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    mobile: "",
    email: "",
    designation: null,  // initialized as null since this is an object
  });
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [showForm, setShowForm] = useState(true);
  const [showThankYou, setShowThankYou] = useState(false);
  const [partnerID, setPartnerID] = useState("");
  const [designation, setDesignation] = useState([]);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otpStatus, setOtpStatus] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const showOTP = 0;

  useEffect(() => {
    const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
    const dealerId = loginUserInfo?.user_data?.dealer_id;
    setPartnerID(dealerId);
    getDesignationList();
  }, []);

  const getDesignationList = async () => {
    const response = await MasterService.get("/partner/partner/get-designation");
    setDesignation(response.data.message);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "mobile") {
      if (!/^\d*$/.test(value) || value.length > 10) return;
    }
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    let errors = {};
    if (!formData.fullName.trim()) {
      errors.fullName = "Full Name is required";
    }
    if (!formData.mobile.trim()) {
      errors.mobile = "Mobile number is required";
    } else if (!/^[6-9][0-9]{9}$/.test(formData.mobile)) {
      errors.mobile = "Mobile number must start with 6, 7, 8, or 9 and be 10 digits long";
    }
    if (!formData.designation) {
      errors.designation = "Designation is required";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSelectChange = (selectedOption) => {
    setFormData({ ...formData, designation: selectedOption });
  };

  const verifyOtp = (data) => {
    if (showOTP === 0) {
      handleSubmit();
    } else {
      setShowOtpModal(true);
      setOtpStatus(data);
      if (data === true) {
        handleSubmit();
      }
    }
  };

  const handleSubmit = async () => {
    if (validate()) {
      setLoading(true);
      setIsSubmitDisabled(true);
      try {
        await RegisterPartner();
      } catch (error) {
        console.error("Error:", error);
        setIsSubmitDisabled(false);
      } finally {
        setLoading(false);
      }
    }
  };

  const RegisterPartner = async () => {
    const reqData = {
      name: formData.fullName,
      mobile: formData.mobile,
      email: formData.email,
      user_id: 0,
      user_type: "61",
      term_condition: true,
      parent_partner_id: partnerID,
      designation: formData.designation.id,
      profession_id: 1,
      source: "Team Member Creation",
      applicant_type_id: 1,
      step: 1,
      updated_by: partnerID,
    };

    try {
      const response = await MasterService.post("/partner/partner/save-basic-details", reqData);
      if (response.data.status !== 200) {
        toast.error(response.data.message[0]);
        return;
      }
      setShowThankYou(true);
      setShowForm(false);
    } catch (error) {
      console.error("Error registering partner:", error);
    }
  };

  const handleNewTeam = () => {
    window.location.reload();
  };

  return (
    <>
      {loading && <PageLoader />}
      {showForm && (
        <>
          <h4>Add Team Member</h4>
          <div className="addteammember">
            <img
              src={require(`../../../src/saathi-web/images/addteamicon.svg`)}
              alt="addteam"
              width={80}
            />
          </div>
          <div className="row">
            <div className="col-md-6">
              <InputField
                type="text"
                name="fullName"
                id="full_name"
                placeholder="Full Name"
                label={
                  <>
                    Full Name<span style={{ color: "red" }}>*</span>
                  </>
                }
                value={formData.fullName}
                onChange={handleChange}
                error={formErrors.fullName}
              />
            </div>
            <div className="col-md-6">
              <InputField
                type="tel"
                name="mobile"
                id="mobile"
                placeholder="Mobile*"
                label={
                  <>
                    Mobile<span style={{ color: "red" }}>*</span>
                  </>
                }
                maxLength="10"
                value={formData.mobile}
                onChange={handleChange}
                error={formErrors.mobile}
              />
            </div>
            <div className="col-md-6">
              <InputField
                type="text"
                name="email"
                id="email"
                placeholder="Email"
                label="Email Address (Optional)"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6">
              <label>
                Designation<span style={{ color: "red" }}>*</span>
              </label>
              <Select
                id="designation"
                options={designation}
                onChange={handleSelectChange}
                name="designation"
                value={formData.designation}
                getOptionLabel={({ designation }) => designation}
                getOptionValue={({ id }) => id}
              />
              <p style={{ color: "red", fontSize: "12px" }}>
                {formErrors.designation}
              </p>
            </div>
          </div>
          <div className="newleadbtnflex">
            <button className="submitbtnnewlead"  onClick={verifyOtp}>
              Submit
            </button>
          </div>
        </>
      )}
      {showThankYou && (
        <>
          <div className="newleadthankyou">
            <img
              src={require(`../../../src/saathi-web/images/thankyouimg.svg`)}
              alt="thankyou"
              width={300}
            />
            <h4 style={{ marginTop: 20 }}>Congratulations</h4>
            <h4>You have Successfully Added Team Member.</h4>
          </div>
          <div className="newleadbtnflex">
            <Link to="/my-saathi-teams">
              <button className="submitbtnnewlead" onClick={handleNewTeam}>
                Go to Team
              </button>
            </Link>
          </div>
        </>
      )}
      {showOtpModal && (
        <VerifyOtpModal mobile={formData.mobile} verifyOtp={verifyOtp} />
      )}
    </>
  );
};

export default NewTeamMember;
